<template>
  <div class="process__slider-tab-inner">
    <div class="process__slider-tab-col -img">
      <div class="process__slider-img-wrap">
        <img
          :src="slider.img"
          loading="lazy"
          alt="Как это работает?"
          class="process__slider-img"
          height="690"
          width="584"
        />
      </div>
    </div>
    <div class="process__slider-tab-col -slider">
      <div class="process__slider-wrap">
        <swiper ref="processCustomerSwiper" class="process__slider swiper-container swiper" :options="swiperOptions">
          <swiper-slide
            v-for="(slide, index) in slider.slides"
            :key="slide.title"
            class="process__slide process-slide swiper-slide"
          >
            <div class="process-slide__step">Шаг {{ index + 1 }} из {{ slider.slides.length }}</div>
            <h3 class="process-slide__title">{{ slide.title }}</h3>
            <ul class="process-slide__list">
              <li v-for="item in slide.listItems" :key="item" class="process-slide__item" v-html="item"></li>
            </ul>
            <UIButtonCta @click="slide.button.action">{{ slide.button.text }}</UIButtonCta>
          </swiper-slide>
        </swiper>
        <div class="index-page__slider-arrows">
          <div class="swiper-button-prev">
            <svg width="20" height="16" viewBox="0 0 20 16" fill="black" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.59844 0.679687L0.398438 7.87969L7.59844 15.0797L9.29644 15.0797L2.69644 8.47969L19.5984 8.47969V7.27969L2.69644 7.27969L9.29644 0.679687L7.59844 0.679687Z"
              />
            </svg>
          </div>
          <div class="swiper-button-next" data-note="0">
            <div class="circle">
              <svg width="36" height="36" class="circle__svg">
                <circle cx="18" cy="18" r="16" class="circle__progress circle__progress--path"></circle>
                <circle cx="18" cy="18" r="16" class="circle__progress circle__progress--fill"></circle>
              </svg>
            </div>
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="black"
              xmlns="http://www.w3.org/2000/svg"
              class="swiper-button-next__svg"
            >
              <path
                d="M12.3977 15.0801L19.5977 7.88008L12.3977 0.680077L10.6997 0.680077L17.2997 7.28008L0.397658 7.28008L0.397658 8.48008L17.2997 8.48008L10.6997 15.0801L12.3977 15.0801Z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIButtonCta from '@/components/ui/UIButtonCta.vue'

export default {
  name: 'IndexHowItWorksSlider',
  components: { UIButtonCta },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    slider: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      swiperOptions: {
        autoplay: {
          delay: 6000,
        },
      },
      transitionDuration: 6000,
      unwatchIsActive: null,
      smallAlert: false,
      message: '',
    }
  },
  computed: {
    swiper() {
      return this.$refs.processCustomerSwiper.swiper
    },
  },
  mounted() {
    this.initSwiperNavigation(this.swiper)
    this.initSwiperCallbacks(this.swiper)
    if (this.isActive) {
      this.swiper.update()
    }
    this.unwatchIsActive = this.$watch('isActive', (newIsActive) => {
      if (newIsActive) {
        this.updateSwiper()
      }
    })
  },
  destroyed() {
    this.unwatchIsActive()
  },
  methods: {
    strokeTransition(swiperButtonNext, note) {
      setTimeout(() => {
        const progress = swiperButtonNext.querySelector('.circle__progress--fill')
        const radius = progress.r.baseVal.value
        const circumference = 2 * Math.PI * radius
        const offset = (circumference * (10 - note)) / 10
        progress.style.setProperty('--initialStroke', circumference)
        progress.style.setProperty('--transitionDuration', `${this.transitionDuration}ms`)
        progress.style.strokeDashoffset = offset
      }, 0)
    },
    strokeReset(swiper) {
      const swiperButtonNext = swiper.el.parentNode.querySelector('.swiper-button-next')
      const note = parseFloat(swiperButtonNext.dataset.note)
      const progress = swiperButtonNext.querySelector('.circle__progress--fill')
      progress.classList.remove('js-hidden')
      progress.style.setProperty('--transitionDuration', '0ms')
      progress.style.setProperty('--initialStroke', '0')
      progress.style.strokeDashoffset = '0'
      this.strokeTransition(swiperButtonNext, note)
    },
    strokeDisable(swiper) {
      const swiperButtonNext = swiper.el.parentNode.querySelector('.swiper-button-next')
      const progress = swiperButtonNext.querySelector('.circle__progress--fill')
      progress.classList.add('js-hidden')
    },
    initSwiperCallbacks(swiper) {
      swiper.on('init', () => {
        const swiperButtonNext = swiper.el.parentNode.querySelector('.swiper-button-next')
        const note = parseFloat(swiperButtonNext.dataset.note)
        this.strokeTransition(swiperButtonNext, note)
        this.strokeReset(swiper)
      })
      swiper.on('transitionEnd', () => {
        if (this.swiper.autoplay.running) {
          this.strokeReset(swiper)
        }
      })
      swiper.on('slideChangeTransitionStart', () => {
        if (!this.swiper.autoplay.running) {
          this.strokeDisable(swiper)
        }
      })
      swiper.on('update', () => {
        this.strokeReset(swiper)
      })
    },
    initSwiperNavigation(swiper) {
      swiper.params.navigation.nextEl = this.$el.querySelector('.swiper-button-next')
      swiper.params.navigation.prevEl = this.$el.querySelector('.swiper-button-prev')
      swiper.navigation.init()
    },
    updateSwiper() {
      this.swiper.update()
      this.swiper.slideTo(0, 0)
      this.swiper.update()
      this.swiper.autoplay.start()
      const swiperButtonNext = this.swiper.el.parentNode.querySelector('.swiper-button-next')
      const note = parseFloat(swiperButtonNext.dataset.note)
      this.strokeTransition(swiperButtonNext, note)
    },
  },
}
</script>

<style scoped lang="scss">
.index-page__slider-arrows {
  position: absolute;
  z-index: 1;
  cursor: pointer;
}
</style>

<style>
.swiper-button-disabled {
  opacity: 0.3;
}
</style>
