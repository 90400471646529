<template>
  <button class="ui-button-cta" :disabled="disabled" @click="$emit('click')">
    <span class="ui-button-cta-inner">
      <slot />
    </span>
    <span class="ui-button-cta-angle" />
  </button>
</template>

<script>
export default {
  name: 'UIButtonCta',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables';

.ui-button-cta {
  border-radius: 0;
  border: 0;
  display: flex;

  background-color: $active-red;
  &-inner {
    display: block;
    height: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $white;
    padding: 10px 20px;
    background: linear-gradient(to right, black 50%, $active-red 50%) right bottom;
    background-size: 200% 100%;
    transition: background-position 0.3s ease-out;
  }
  &-angle {
    width: 44px;
    height: 44px;
    background: $active-red url(@/assets/images/icons/right-shevron.svg) no-repeat center;
  }
  &:hover {
    .ui-button-cta-inner {
      background-position: left bottom;
    }
  }
  &:active {
    .ui-button-cta-angle {
      background-color: $black;
    }
  }
  &:disabled {
    cursor: default;

    .ui-button-cta-inner,
    .ui-button-cta-angle {
      background: $tint;
    }
  }
}

@keyframes colorAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -100% 0;
  }
}
</style>
