// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/icons/right-shevron.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-button-cta[data-v-2cafa232]{background-color:#e50040;border:0;border-radius:0;display:flex}.ui-button-cta-inner[data-v-2cafa232]{background:linear-gradient(90deg,#000 50%,#e50040 0) 100% 100%;background-size:200% 100%;color:#fff;display:block;font-size:18px;font-weight:400;height:100%;line-height:24px;padding:10px 20px;transition:background-position .3s ease-out}.ui-button-cta-angle[data-v-2cafa232]{background:#e50040 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;height:44px;width:44px}.ui-button-cta:hover .ui-button-cta-inner[data-v-2cafa232]{background-position:0 100%}.ui-button-cta:active .ui-button-cta-angle[data-v-2cafa232]{background-color:#000}.ui-button-cta[data-v-2cafa232]:disabled{cursor:default}.ui-button-cta:disabled .ui-button-cta-angle[data-v-2cafa232],.ui-button-cta:disabled .ui-button-cta-inner[data-v-2cafa232]{background:#d9d9d9}@keyframes colorAnimation-2cafa232{0%{background-position:0 0}to{background-position:-100% 0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
